/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import FormLoading from "../../loader/form_loading";
import base64 from "base-64";
import { SendPostRequest } from "../../util/request";
import { useStoreState, useStoreActions } from "easy-peasy";

const AccTopUps = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);
	const account = useStoreState((state) => state.account);

	const [dataList, setDataList] = useState({
		transactionId: "",
		nameOnAccount: "",
		reference: "",
		amount: "0",
	});
	const [processedData, setProcessedData] = useState([]);

	const [transactionList, setTransactionList] = useState([]);

	const manualProcess = () => {
		setLoaderShow(true);

		SendPostRequest(appLink.ussdManualTopupEndpoint, {
			data: processedData,
			serverKey: base64.decode("JDJhJDExJDdMcWp0cVFoR2VkREVGYXk5WDZsWk9rVkZ3VDdzVndPeXREL051UDFRNEdxOGg5OFlQa3Fx"),
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					window.swal({
						text: `Sent ${transactionList.length} transaction(s) for processing`,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});

					setTransactionList([]);
					setProcessedData([]);

					setDataList({
						transactionId: "",
						nameOnAccount: "",
						reference: "",
						amount: "0",
					});

					for (let index = 0; index < processedData.length; index++) {
						const tx = processedData[index];

						const message = `${account.fullName} submitted a top up request for ${tx.accountName} for GHS ${tx.cashoutAmount}. Transaction ID:${tx.transactionId}`;
						fetch(encodeURI(`https://api.telegram.org/bot5504409777:AAGucseSqyK2pevcJFqmHAXzA5DVMAealEI/sendMessage?chat_id=-1001706461069&text=${message}&protect_content=true`), {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify({}),
						});
					}
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const addToQueue = async (e) => {
		e.preventDefault();

		if (dataList.transactionId.length < 5) {
			return window.swal({
				text: "Invalid Transaction ID",
				button: {
					text: "OK",
					value: true,
					visible: true,
					className: "btn btn-primary",
				},
			});
		}

		if (dataList.reference.length < 5) {
			return window.swal({
				text: "Invalid Reference ID",
				button: {
					text: "OK",
					value: true,
					visible: true,
					className: "btn btn-primary",
				},
			});
		}

		if (dataList.nameOnAccount.length < 5) {
			return window.swal({
				text: "Invalid Name on Account",
				button: {
					text: "OK",
					value: true,
					visible: true,
					className: "btn btn-primary",
				},
			});
		}

		if (Number(dataList.amount) < 1) {
			return window.swal({
				text: "Enter a valid amount above 1",
				button: {
					text: "OK",
					value: true,
					visible: true,
					className: "btn btn-primary",
				},
			});
		}

		setTransactionList((s) => [...s, dataList]);

		setProcessedData((s) => [
			...s,
			{
				uniqId: dataList.reference.trim(),
				transactionId: dataList.transactionId.trim(),
				cashoutAmount: dataList.amount.trim(),
				accountName: dataList.nameOnAccount.trim(),
				message: "Cash Out made for GHS 1.00 for FREDERICK KWABENA NKRUMAH.Current Balance GHS 86.57 Available Balance GHS 86.57.Financial Transaction ID: 00000000.Tran",
				messageIndex: "0",
				messageStatus: "REC READ",
				messageDate: "24/01/02 18:20:21+00",
			},
		]);

		setDataList({
			transactionId: "",
			nameOnAccount: "",
			reference: "",
			amount: "5",
		});
	};

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">MANUAL ACCOUNT TOP UP</h4>
							<div className="row">
								<div className="col-12 row mt-4">
									<div className="col-lg-2">
										<div className="form-group mb-3">
											<label>Transaction ID</label>
											<input
												type="text"
												className="form-control"
												required={true}
												value={dataList.transactionId}
												onChange={(e) => {
													e.preventDefault();
													setDataList({
														...dataList,
														transactionId: e.target.value,
													});
												}}
											/>
										</div>
									</div>

									<div className="col-lg-2">
										<div className="form-group mb-3">
											<label>Reference</label>
											<input
												type="text"
												className="form-control"
												required={true}
												value={dataList.reference}
												onChange={(e) => {
													e.preventDefault();
													setDataList({
														...dataList,
														reference: e.target.value,
													});
												}}
											/>
										</div>
									</div>

									<div className="col-lg-4">
										<div className="form-group mb-3">
											<label>Exact Name on Payment Account</label>
											<input
												type="text"
												className="form-control"
												required={true}
												value={dataList.nameOnAccount}
												onChange={(e) => {
													e.preventDefault();
													setDataList({
														...dataList,
														nameOnAccount: e.target.value,
													});
												}}
											/>
										</div>
									</div>

									<div className="col-lg-2">
										<div className="form-group mb-3">
											<label>Amount (eg 25.00)</label>
											<input
												type="number"
												className="form-control"
												min={1}
												step="0.1"
												required={true}
												value={dataList.amount}
												onChange={(e) => {
													e.preventDefault();
													setDataList({
														...dataList,
														amount: e.target.value,
													});
												}}
											/>
										</div>
									</div>

									<div className="col-lg-2">
										<div className="form-group mb-3">
											<label></label>
											<button className="btn btn-sm btn-success mt-4" onClick={(e) => addToQueue(e)}>
												Add to Queue
											</button>
										</div>
									</div>
								</div>
							</div>

							{transactionList.length > 0 && (
								<React.Fragment>
									<div className="row mt-4">
										<h3 className="col-12">Queue</h3>
										<div className="col-12 table-responsive">
											<table id="order-listing" className="table">
												<thead>
													<tr>
														<th>#</th>
														<th>Transaction ID</th>
														<th>Name</th>
														<th>Amount</th>
														<th>Order Ref.</th>
													</tr>
												</thead>
												<tbody>
													{transactionList.map((user, index) => (
														<tr key={index}>
															<td>{index}</td>
															<td>
																<span className="badge badge-primary">{user.transactionId}</span>
															</td>
															<td>{user.nameOnAccount}</td>
															<td>{user.amount}</td>
															<td>{user.reference}</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
										<div className="col-12">
											<button className="btn btn-sm btn-success mt-4" onClick={(e) => manualProcess(e)}>
												Process Payments
											</button>
										</div>
									</div>
								</React.Fragment>
							)}
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default AccTopUps;
