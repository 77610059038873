/* eslint-disable import/no-anonymous-default-export */
export default {
	appRoot: "/signin",
	signIn: "/signin",
	dashboard: "/dashboard",
	tronBoard: "/tron-board",
	evmBoard: "/evm-board",
	users: "/users",
	searchUsers: "/search/user",
	searchMomoDeposit: "/search/momoDeposit",
	searchMomoWithdrawal: "/search/momoWithdrawal",
	searchTransaction: "/search/transaction",
	user: "/user/:id/",
	usersInActive: "/inactive",
	accountTopUps: "/account/topups",
	accountTopUpsManual: "/account/topups/manual",
	manualTopup: "/manual/topup",
	accountTopUpsFailed: "/account/topups/failed",
	addresses: "/addresses",
	broadcast: "/broadcast",
	emailBroadcast: "/email/sendbroadcast",
	airTimeTopUps: "/airtime/topups",
	countries: "/countries",
	balance: "/balances",
	kyc: "/kyc",
	cardKycs: "/virtual-cards-kycs",
	admin: "/admin",
	pendingKYC: "/kyc/pending",
	errorKYC: "/kyc/failed",
	site: "/site",
	momo: "/momo",
	bank: "/bank",
	chipper: "/chipper",
	transactions: "/transactions",
	withdrawals: "/withdrawals",
	withdrawalsPending: "/withdrawals/pending",
	signInEndpoint: "https://ghana.btfa.xyz/admin/signIn",
	usersEndpoint: "https://ghana.btfa.xyz/admin/activeUsers",
	searchUserEndpoint: "https://ghana.btfa.xyz/admin/searchUser",
	searchDepositEndpoint: "https://ghana.btfa.xyz/admin/searchDeposit",
	searchWithdrawalEndpoint: "https://ghana.btfa.xyz/admin/searchWithdrawal",
	searchTransactionEndpoint: "https://ghana.btfa.xyz/admin/searchTransaction",
	disabledUsersEndpoint: "https://ghana.btfa.xyz/admin/disabledUsers",
	userAccountStatusEndpoint: "https://ghana.btfa.xyz/admin/userStatus",
	userAccountTopUpsEndpoint: "https://ghana.btfa.xyz/admin/topUps",
	userAccountWithdrawalsEndpoint: "https://ghana.btfa.xyz/admin/withdrawals",
	fundUserAccountTopUpsEndpoint: "https://ghana.btfa.xyz/admin/fundTopUpRequest",
	viewPaymentMethodEndpoint: "https://ghana.btfa.xyz/admin/viewPaymentMethod",
	getAddressesEndpoint: "https://ghana.btfa.xyz/admin/addresses",
	sendSMSBroadcastEndpoint: "https://ghana.btfa.xyz/admin/broadcastSMS",
	sendEmailBroadcastEndpoint: "https://ghana.btfa.xyz/admin/broadcastEmail",
	airtimeEndpoint: "https://ghana.btfa.xyz/admin/airtimeTopUps",
	getCountriesEndpoint: "https://ghana.btfa.xyz/admin/countries",
	sendSMSEndpoint: "https://ghana.btfa.xyz/admin/sendSMS",
	getUserInformationEndpoint: "https://ghana.btfa.xyz/admin/userInfo",
	createCountryEndpoint: "https://ghana.btfa.xyz/admin/createCountry",
	updateCountryEndpoint: "https://ghana.btfa.xyz/admin/updateCountry",
	deleteCountryEndpoint: "https://ghana.btfa.xyz/admin/deleteCountry",
	userBalanceEndpoint: "https://ghana.btfa.xyz/admin/userBalance",
	fundBalanceEndpoint: "https://ghana.btfa.xyz/admin/fundUserBalance",
	kycEndpoint: "https://ghana.btfa.xyz/admin/kycs",
	updateUserEndpoint: "https://ghana.btfa.xyz/admin/updateKYCStatus",
	siteConfig: "https://ghana.btfa.xyz/admin/config",
	financialReport: "https://ghana.btfa.xyz/admin/financeReport",
	tronBoardReport: "https://ghana.btfa.xyz/admin/tron/report",
	evmBoardReport: "https://ghana.btfa.xyz/admin/evm/report",
	listAdmin: "https://ghana.btfa.xyz/admin/listAdmin",
	deleteAdmin: "https://ghana.btfa.xyz/admin/deleteAdmin",
	createAdmin: "https://ghana.btfa.xyz/admin/createAdmin",
	updateSiteConfig: "https://ghana.btfa.xyz/admin/updateConfig",
	updateBuyLimit: "https://ghana.btfa.xyz/admin/updateBuyLimit",
	paymentMethods: "https://ghana.btfa.xyz/admin/paymentMethods",
	transactionsEndpoint: "https://ghana.btfa.xyz/admin/transactions",
	updateMethod: "https://ghana.btfa.xyz/admin/updateMethod",
	serverBaseEndpoint: "https://ghana.btfa.xyz/",
	listCardKYCs: "https://ghana.btfa.xyz/admin/profile-data-requests",
	approveCardKYC: "https://ghana.btfa.xyz/admin/profile-data-requests-approve",
	rejectCardKYC: "https://ghana.btfa.xyz/admin/profile-data-requests-reject",
	manualTopupEndpoint: "https://ghana.btfa.xyz/gh/v3/payments/topupHook",
	ussdManualTopupEndpoint: "https://ghana.btfa.xyz/gh/v3/payments/ussdHook",
	viewPendingWithdrawals: "https://ghana.btfa.xyz/admin/pendingWithdrawals",
	viewSuccessfulWithdrawals: "https://ghana.btfa.xyz/admin/successWithdrawals",
	updateWithdrawalStatus: "https://ghana.btfa.xyz/admin/updateWithdrawalStatus",
};
