//TODO: Add a name field ( base64 ) when adding a momo payment number
// Query the country sell rate instead, when withdrawing user balance
// Remove user from disabling a phone number. Admin only
import React from "react";
import ReactDOM from "react-dom";
import { ProtectedRoute, UnAuthenticatedOnly } from "./util/accessRoute";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import SignInUI from "./components/signin";
import UsersList from "./components/user/users";
import UserInfo from "./components/user/users/userBodyIndex";
import DisabledUsersList from "./components/user/inactiveUsers";
import AccountTops from "./components/user/accountTopUps";
import AccountWithdrawals from "./components/user/accountWithdrawals";
import PendingAccountTops from "./components/user/pendingAccountTopUps";
import AddressList from "./components/user/addresses";
import CountryList from "./components/user/countries";
import UserKYCs from "./components/user/kyc";
import AdminConfig from "./components/user/admin";
import PendingUserKYCs from "./components/user/kyc/pendingIndex";
import FailedUserKYCs from "./components/user/kyc/failedIndex";
import PaymentsUI from "./components/user/payments/momoIndex";
import BankPaymentsUI from "./components/user/payments/bankIndex";
import ChipperPaymentsUI from "./components/user/payments/chipperIndex";
import SiteConfig from "./components/user/site";
import PendingWithdrawals from "./components/user/withdrawals/pendingIndex";
// import SuccessfulWithdrawals from "./components/user/withdrawals/successIndex";
import ManualAccountTops from "./components/user/accountTopUps/manualIndex";
import Transactions from "./components/user/transactions";
import UserBalanceUI from "./components/user/balance";
import SearchUser from "./components/user/search/searchUserIndex.jsx";
import SearchMomoDeposit from "./components/user/search/searchMomoDeposit";
import SearchMomoWithdrawal from "./components/user/search/searchMomoWithdrawal";
import SearchTransaction from "./components/user/search/searchTransactionIndex";
import SMSBroadcast from "./components/user/site/smsIndex";
import EmailBroadcast from "./components/user/site/emailIndex";
import AirTimeTopUps from "./components/user/airtimeTopups";
import { StoreProvider } from "easy-peasy";
import PlatformDashboard from "./components/user/dashboard";
import PlatformTRONDashboard from "./components/user/dashboard/trxIndex";
import PlatformEVMDashboard from "./components/user/dashboard/evmIndex";
import CardKYCs from "./components/user/cardKycs";
import store from "./easyPeasy";
import * as appLinks from "./components/util/app_links";

import "react-image-lightbox/style.css";
//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
	<React.StrictMode>
		<StoreProvider store={store}>
			<Router>
				<Switch>
					<UnAuthenticatedOnly exact path={appLinks.default.signIn} component={SignInUI} />
					<ProtectedRoute path={appLinks.default.user} component={UserInfo} />
					<ProtectedRoute exact path={appLinks.default.manualTopup} component={ManualAccountTops} />
					<ProtectedRoute exact path={appLinks.default.users} component={UsersList} />
					<ProtectedRoute exact path={appLinks.default.broadcast} component={SMSBroadcast} />
					<ProtectedRoute exact path={appLinks.default.emailBroadcast} component={EmailBroadcast} />
					<ProtectedRoute exact path={appLinks.default.usersInActive} component={DisabledUsersList} />
					<ProtectedRoute exact path={appLinks.default.accountTopUps} component={AccountTops} />
					<ProtectedRoute exact path={appLinks.default.accountTopUpsManual} component={PendingAccountTops} />
					<ProtectedRoute exact path={appLinks.default.addresses} component={AddressList} />
					<ProtectedRoute exact path={appLinks.default.countries} component={CountryList} />
					<ProtectedRoute exact path={appLinks.default.balance} component={UserBalanceUI} />
					<ProtectedRoute exact path={appLinks.default.kyc} component={UserKYCs} />
					<ProtectedRoute exact path={appLinks.default.pendingKYC} component={PendingUserKYCs} />
					<ProtectedRoute exact path={appLinks.default.errorKYC} component={FailedUserKYCs} />
					<ProtectedRoute exact path={appLinks.default.withdrawalsPending} component={PendingWithdrawals} />
					<ProtectedRoute exact path={appLinks.default.withdrawals} component={AccountWithdrawals} />
					<ProtectedRoute exact path={appLinks.default.momo} component={PaymentsUI} />
					<ProtectedRoute exact path={appLinks.default.searchUsers} component={SearchUser} />
					<ProtectedRoute exact path={appLinks.default.searchMomoDeposit} component={SearchMomoDeposit} />
					<ProtectedRoute exact path={appLinks.default.searchMomoWithdrawal} component={SearchMomoWithdrawal} />
					<ProtectedRoute exact path={appLinks.default.searchTransaction} component={SearchTransaction} />
					<ProtectedRoute exact path={appLinks.default.bank} component={BankPaymentsUI} />
					<ProtectedRoute exact path={appLinks.default.chipper} component={ChipperPaymentsUI} />
					<ProtectedRoute exact path={appLinks.default.transactions} component={Transactions} />
					<ProtectedRoute exact path={appLinks.default.site} component={SiteConfig} />
					<ProtectedRoute exact path={appLinks.default.cardKycs} component={CardKYCs} />
					<ProtectedRoute exact path={appLinks.default.admin} component={AdminConfig} />
					<ProtectedRoute exact path={appLinks.default.airTimeTopUps} component={AirTimeTopUps} />
					<ProtectedRoute exact path={appLinks.default.dashboard} component={PlatformDashboard} />
					<ProtectedRoute exact path={appLinks.default.tronBoard} component={PlatformTRONDashboard} />
					<ProtectedRoute exact path={appLinks.default.evmBoard} component={PlatformEVMDashboard} />
					<UnAuthenticatedOnly exact path="/" component={SignInUI} />
					<UnAuthenticatedOnly component={SignInUI} />
				</Switch>
			</Router>
		</StoreProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
