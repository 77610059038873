/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormLoading from "../../loader/form_loading";
import { isUuid } from "uuidv4";
import { useStoreState } from "easy-peasy";
import { SendPostRequest } from "../../util/request";
import { validate_amount } from "../../util/misc";

const KYCBody = ({ appLink }) => {
	const [error, setError] = useState(null);
	const [loaderShow, setLoaderShow] = useState(false);

	const account = useStoreState((state) => state.account);

	const [currentDate, setCurrentDate] = useState(new Date().toISOString().split("T")[0]);

	const [kycCardData, setKycCardData] = useState([]);

	const [page, setPage] = useState("1");

	const retrieveKycs = (date) => {
		setLoaderShow(true);

		SendPostRequest(appLink.listCardKYCs, {
			token: account.token,
			page,
		})
			.then(function (response) {
				setLoaderShow(false);

				if (response.data.success) {
					setKycCardData(response.data.data);
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const approveCardKyc = (e, username, id) => {
		e.preventDefault();

		setLoaderShow(true);

		SendPostRequest(appLink.approveCardKYC, {
			username,
			id,
			token: account.token,
		})
			.then(function (response) {
				const activeKycs = kycCardData.filter((user) => user.kycRequest.id !== id);
				setKycCardData(activeKycs);

				setLoaderShow(false);

				if (response.data.success) {
					window.swal({
						text: `The profile update for (${username}) was successful.`,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	const rejectCardKyc = (e, username, id) => {
		e.preventDefault();

		setLoaderShow(true);

		SendPostRequest(appLink.rejectCardKYC, {
			username,
			id,
			token: account.token,
		})
			.then(function (response) {
				const activeKycs = kycCardData.filter((user) => user.kycRequest.id !== id);
				setKycCardData(activeKycs);

				setLoaderShow(false);

				if (response.data.success) {
					window.swal({
						text: `The profile update for (${username}) was rejected.`,
						button: {
							text: "OK",
							value: true,
							visible: true,
							className: "btn btn-primary",
						},
					});
				} else {
					setError(response.data.message);
				}
			})
			.catch((error) => {
				setLoaderShow(false);

				setError("Unable to connect to server. Check Internet");
			});
	};

	useEffect(() => {
		retrieveKycs();
	}, []);

	return (
		<React.Fragment>
			<div className="card">
				{error !== null && (
					<div className="alert alert-danger alert-fill-danger alert-dismissible fade show" role="alert">
						{error}
						<button type="button" className="close" data-dismiss="alert" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				)}

				{!loaderShow ? (
					<React.Fragment>
						<div className="card-body">
							<h4 className="card-title">Pending Virtual Cards KYC Approval</h4>

							<div className="row">
								<div className="col-12 table-responsive">
									<table id="order-listing" className="table dataTable">
										<thead>
											<tr>
												<th>Username</th>
												<th>Name/DOB</th>
												<th>Age</th>
												<th>Address</th>
												<th>ID type/ Number</th>
												<th>Metamap ID</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											{kycCardData !== null &&
												kycCardData.map((kyc, index) => (
													<tr key={index}>
														<td>
															<small>
																<a href={appLink.user.replace(":id", kyc.kycRequest.username)} target="_blank" rel="noopener noreferrer">
																	@{kyc.kycRequest.username}
																</a>
															</small>
														</td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td></td>
														<td>
															<button className="btn btn-primary" onClick={(e) => approveCardKyc(e, kyc.kycRequest.username, kyc.kycRequest.id)}>
																Approve
															</button>

															<button className="ml-2 btn btn-outline-danger" onClick={(e) => rejectCardKyc(e, kyc.kycRequest.username, kyc.kycRequest.id)}>
																Reject
															</button>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</React.Fragment>
				) : (
					<FormLoading />
				)}
			</div>
		</React.Fragment>
	);
};

export default KYCBody;
